import React , { useEffect}from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import phone_img from "../assets/images/phone_img.png";
const PhoneComponent = () => {
    useEffect(() => {
        AOS.init(
            {
                disable:"phone",
            }

        );
      }, []);

    return (
        <div className=' fixed bottom-[55px] left-[40px] sm:left-[40px] md:left-[55px] z-10'>
            <a href="tel:+919566661423" className=' relative z-10'>
                <img src={phone_img} className='w-[40px] bg-[white] rounded-[100px] sm:w-[40px] md:w-[50px] lg:w-[60px] ' alt="whatsapp_img" />
            </a>
            <div className="circle w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]  h-[40px] sm:h-[40px] md:h-[50px] lg:h-[60px]" style={{ animationDelay: "-3s" }}></div>
            <div className="circle w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]  h-[40px] sm:h-[40px] md:h-[50px] lg:h-[60px]" style={{ animationDelay: "-2s" }}></div>
            <div className="circle w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]  h-[40px] sm:h-[40px] md:h-[50px] lg:h-[60px]" style={{ animationDelay: "-1s" }}></div>
            <div className="circle w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]  h-[40px] sm:h-[40px] md:h-[50px] lg:h-[60px]" style={{ animationDelay: "-0s" }}></div>
        </div>
    )
}

export default PhoneComponent