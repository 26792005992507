import React from 'react'
import TopRoutesGrid from './TopRoutesGrid'
import coim from '../../assets/images/coim.jpeg'
import salem from "../../assets/images/salem.jpeg";
import trichy from "../../assets/images/trichy.jpeg";
import pondy from "../../assets/images/pondy.jpeg";
import bng from "../../assets/images/bangalore.jpeg";
import madurai from "../../assets/images/madurai.jpg";
import tiruvannamalai from "../../assets/images/thiruvannamalai.jpeg";
import tirupathi from "../../assets/images/tirupathi.jpeg";
import ooty from "../../assets/images/ooty.jpeg";
import chennai from "../../assets/images/chennai.jpeg";
import dharmapuri from "../../assets/images/dharmapuru.jpeg";
import pollachi from "../../assets/images/pollachi.jpeg";


const TopRoutes = () => {
    return (
        <div className='container mx-auto mt-5 px-5'>
            <h4 className='text-[30px] sm:text-[34px] md:text-[36px] font-semibold pt-8'>Top Drop Taxi Routes</h4>
            <p className='text-[#494848] w-full sm:w-[80%] md:w-[60%] mt-2'>Book Online Outstation Taxi Service in and Around Tamil Nadu, Pondicherry and Bangalore. One Way Drop Taxi @ One Way Fare.</p>
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-5 bg-[#f9f9f9] gap-5 p-4'>
                <TopRoutesGrid grid_image={coim} heading="Chennai to Coimbatore Taxi" rates="Rs. 6972" />
                <TopRoutesGrid grid_image={salem} heading="Chennai to Salem Taxi" rates="Rs. 4885" />
                <TopRoutesGrid grid_image={trichy} heading="Chennai to Trichy Taxi" rates="Rs. 4713" />
                <TopRoutesGrid grid_image={pondy} heading="Chennai to Pondicherry Taxi" rates="Rs. 2545" />
                <TopRoutesGrid grid_image={bng} heading="Chennai to Bangalore Taxi" rates="Rs. 4916" />
                <TopRoutesGrid grid_image={madurai} heading="Chennai to Madurai Taxi" rates="Rs. 6410" />
                <TopRoutesGrid grid_image={tiruvannamalai} heading="Chennai to Tiruvannamalai Taxi" rates="Rs. 2922" />
                <TopRoutesGrid grid_image={tirupathi} heading="Chennai to Tirupati Taxi" rates="Rs. 2129" />
                <TopRoutesGrid grid_image={ooty} heading="Coimbatore to Ooty Taxi" rates="Rs. 1512" />
                <TopRoutesGrid grid_image={chennai} heading="Tiruchirappalli to Chennai Taxi" rates="Rs. 4694" />
                <TopRoutesGrid grid_image={dharmapuri} heading="Chennai to Dharmapuri Taxi" rates="Rs. 4305" />
                <TopRoutesGrid grid_image={pollachi} heading="Chennai to Pollachi Taxi" rates="Rs. 7260" />
            </div>
        </div>
    )
}

export default TopRoutes