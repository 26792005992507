import React , { useEffect}from 'react'
import about from "../assets/about.jpg";
import AOS from "aos";
import "aos/dist/aos.css";


const About = () => {
    useEffect(() => {
        AOS.init(
            {
                disable:"phone",
            }

        );
      }, []);
    
    return (
        <div  id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-14 gap-x-20 items-center'>
                    <div className='w-full md:w-1/2'>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto w-[500px] car_outline' data-aos="fade-right"/>
                            <div className=' absolute bottom-2 md:bottom-6 right-1/2 translate-x-1/2 md:translate-x-0 md:right-[-40px]  bg-white py-4 px-4 rounded-xl shadow-md'>
                                <div className=' flex flex-col gap-y-1 items-center' data-aos="fade-up">
                                    <span className='primary-color text-4xl font-bold' >10+</span>
                                    <span className=' text-lg opacity-60 font-medium' >Years Experience</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='mt-20 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium primary-color' data-aos="zoom-in-out">About Us</h4>
                        <div className='text-3xl md:text-4xl font-bold mt-4' data-aos="zoom-in-out">Convenient And Affordable One Way Taxi Services For Easy Point-To-Point Travel</div>
                        <p className='text-black opacity-60 md:text-lg mt-5' data-aos="zoom-in-out">Drop Taxi Services based in Chennai, India, specializing in outstation cab services, drop taxi services, and airport taxi services. With a strong presence in Tamil Nadu and Bangalore, we offer reliable and efficient transportation solutions to our customers.</p>
                        <p className='text-black opacity-60 md:text-lg mt-5' data-aos="zoom-in-out">At Drop Taxi Services, we understand the importance of safe and comfortable travel. Our fleet of well-maintained vehicles ensures a smooth and enjoyable journey for both individuals and groups. Whether you need to travel for business or leisure, we strive to provide a hassle-free experience, allowing you to focus on your destination.</p>
    
                        <div className=' mt-5'>
                        <a  href="tel:+919566661423"  className='th-btn' data-aos="fade-up">Contact for More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About