import React , { useEffect}from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import BookingForm from "../components/BookingForm";
import About from "../components/About";
import PopularRoutes from "../components/PopularRoutes";
import Cars from "../components/Cars";
import Look from "../components/Look";
import OurServices from "../components/Home/OurServices";
import OneWayServices from "../components/Home/OneWayServices";
import TopRoutes from "../components/Home/TopRoutes";
import PricePlan from "../components/Home/PricePlan";

const Home = () => {
  useEffect(() => {
    AOS.init(
        {
            disable:"phone",
        }

    );
  }, []);

  return (
    <>
      <BookingForm />
      <About />
      {/* {/* <OurServices /> */}
      {/* <OneWayServices /> */}
      <PricePlan /> 

      <TopRoutes />
      {/* /* <PopularRoutes /> */} 
      {/* <Cars />  */}
      <Look />
    </>
  );
};

export default Home;
