import React from "react";
import sedan from "../../assets/images/cars/sedan.png";
import etios from "../../assets/images/cars/etios.png";
import suv from "../../assets/images/cars/suv.png";
import innova from "../../assets/images/cars/innova.png";
import PricePlanComponents from "./PricePlanComponents";
const PricePlan = () => {
  return (
    <div className="container py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16" id="tarrif">
      <h4 className=" text-lg font-medium primary-color text-center" data-aos="zoom-in-out">
        Pricing Plan
      </h4>
      <div className="text-3xl md:text-4xl font-bold mt-4 text-center" data-aos="zoom-in-out">
        Our Most Valuable Drop Taxi Pricing
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 mt-5 sm:mt-6 md:mt-8 lg:mt-10" >
        <PricePlanComponents place="SEDAN" image={sedan} oneway="14" oneDriverBeta="400" roundtrip="13" roundDriverBeta="500"/>
        <PricePlanComponents place="ETIOS" image={etios} oneway="15" oneDriverBeta="400" roundtrip="13" roundDriverBeta="500"/>
        <PricePlanComponents place="SUV" image={suv} oneway="19" oneDriverBeta="500" roundtrip="17" roundDriverBeta="600"/>
        <PricePlanComponents place="INNOVA" image={innova} oneway="20" oneDriverBeta="500" roundtrip="18"roundDriverBeta="600"/>
      </div>
    </div>
  );
};

export default PricePlan;
