import React from 'react'

const PricePlanComponents = ({place , image , oneway ,roundtrip,oneDriverBeta,roundDriverBeta}) => {
  return (
    <div className=" py-6 px-4 rounded-lg shadow-lg border" data-aos="zoom-out">
    <div className=' flex flex-col justify-between flex-1 h-full'>
      <p className=" font-bold">{place}</p>
      <img src={image} className=" w-[200px] mx-auto" />
      <div className=" flex gap-x-2 justify-between mt-3 pb-3  border-b flex-1">
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-bold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl ">{oneway}</span>
              </span>
              <span className=" opacity-50 font-semibold">One Way</span>

          </div>
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-semibold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl">{roundtrip}</span>
              </span>
              <span className=" opacity-50 font-semibold">Round Trip</span>
          </div>
         
      </div>

      <div className=" flex gap-x-2 justify-between mt-3 pb-3  border-b flex-1">
      <div className=" flex flex-col gap-y-2 flex-1 items-center">

              <span className="flex gap-x-1 font-semibold align-baseline">
                
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-2xl ">{oneDriverBeta}</span>

              </span>
              <span className=" opacity-50 font-semibold">Driver Beta</span>

          </div>
          <div className=" flex flex-col gap-y-2 flex-1 items-center">

              <span className="flex gap-x-1 font-semibold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-2xl">{roundDriverBeta}</span>

              </span>
              <span className=" opacity-50 font-semibold">Driver Beta</span>

          </div>

</div>
                    <span className=" opacity-50  mt-3 text-center border-b ">One way-Minimum:130 KM.Round Trip-Minimum:250 KM.*</span>
                    <span className=" opacity-50  text-center border-b "><b>Toll Parking,State Permit extra*</b></span>

      <div className=" mt-3">
          <a  href="tel:+919566661423"  className="th-btn w-full">CHOOSE CAB</a>
      </div>
    </div>
  </div>
  )
}

export default PricePlanComponents
